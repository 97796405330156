<template>
  <section class="w-full md:w-1/2 p-5 mx-auto">
    <div id="header" class="flex items-center justify-center mb-4 mr-4">
      <div class="flex align-baseline absolute left-0 top-0">
        <router-link to="/" class="form-button form-button--mini form-button--back form-button--outline uppercase">
          Back
        </router-link>
        <ActionButton class="form-button form-button--mini form-button--outline uppercase pa-1 ml-2">
          <download-csv :data="records" :name="`Audit Trail - ${new Date().toLocaleString()}.csv`">
            Download
          </download-csv>
        </ActionButton>
      </div>
      <h1 class="title">Audit Trail</h1>
    </div>
    <div class="w-full overflow-auto text-center">
      <v-data-table
        :headers="headers"
        :items="records"
        sort-by="timestamp"
        sort-desc
        :footer-props="{ itemsPerPageOptions: [10, 25, 50, -1] }"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:item.timestamp="{ item }">
          {{ getDateTimeString(item.timestamp) }}
        </template>
      </v-data-table>
    </div>
  </section>
</template>

<script>
import { ActionButton } from "@/components";
import { DateTime } from "luxon";
import { mapGetters } from "vuex";

export default {
  name: "AuditTrail",
  components: { ActionButton },
  data: () => ({
    loading: false,
    results: [],
    headers: [
      { text: "Timestamp", align: "start", value: "timestamp" },
      { text: "Username", value: "userName" },
      { text: "Notes", value: "notes" }
    ]
  }),

  methods: {
    getDateTimeString(timestamp) {
      return new DateTime.fromMillis(timestamp).toFormat("dd/LL/yyyy HH:mm");
    }
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      records: "audit/getRecords"
    })
  },

  async created() {
    this.loading = true;

    if (this.currentUser.clinicUid) {
      await this.$store.dispatch("audit/fetchRecordsFromClinic", this.currentUser.clinicUid);
    }
    await this.$store.dispatch("audit/fetchRecordsFromUser", this.currentUser.email || this.currentUser.authEmail);
    this.loading = false;
  }
};
</script>

<style>
th,
td {
  white-space: nowrap;
}
</style>
